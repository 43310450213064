import { useState, useEffect } from "react";
import { projectFirestore, projectDatabase, timestamp } from "../config/config";

export const useNewsFeedPost = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);

  const newsCreate = async (doc) => {
    setError(null);
    setIsPending(true);

    try {
      const createdAt = timestamp.fromDate(new Date());
      const metaData = { deleted: false, comments: [], likes: [] };
      const newsPost = { ...doc, createdAt, ...metaData };
      const resold = await projectFirestore.collection("newsFeed").add({ ...newsPost });
      const res = await projectDatabase
        .ref("newsFeed")
        .child(res.id)
        .set({ ...newsPost });

      if (!res) {
        throw new Error("could not complete submission");
      }

      if (!isCancelled) {
        setIsPending(false);
        setError(null);
      }
    } catch (err) {
      if (!isCancelled) {
        console.error(err.message);
        setError(err.message);
        setIsPending(false);
      }
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  const newsDelete = async (id) => {
    setError(null);
    setIsPending(true);

    try {
      const res = await projectFirestore.collection("newsFeed").doc(id).update({ deleted: true });
      if (!res) {
        throw new Error("could not complete submission");
      }
    } catch (err) {
      if (!isCancelled) {
        console.error(err.message);
        setError(err.message);
        setIsPending(false);
      } else {
        setIsPending(false);
      }
    }
  };

  const newsUpdate = async (id, doc) => {
    setError(null);
    setIsPending(true);
    try {
      const updated = true;
      const createdAt = timestamp.fromDate(new Date());
      const res = await projectFirestore
        .collection("newsFeed")
        .doc(id)
        .update({ ...doc, createdAt, updated });
      if (!res) {
        throw new Error("could not complete submission");
      }
    } catch (err) {
      if (!isCancelled) {
        console.error(err.message);
        setError(err.message);
        setIsPending(false);
      } else {
        setIsPending(false);
      }
    }
  };

  return { error, isPending, newsCreate, newsDelete };
};
