// import './login.css'
import { React, useState } from "react";
import { useLogin } from "../../hooks/useLogin";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import GoogleSignInButton from "../../components/loginMethods/googleSignIn/GoogleSignIn";
import firebase from "firebase";
import TextDivider from "../../components/textDivider/TextDivider";
import { ToastContainer, toast } from "react-toastify";

export default function Login({ setLoading }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, error, isPending } = useLogin();
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");

  const handleToggleForgotPassword = () => {
    setShowForgotPassword(!showForgotPassword);
    setForgotPasswordEmail("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  const handleForgotPassword = async () => {
    try {
      await firebase.auth().sendPasswordResetEmail(forgotPasswordEmail);
      toast.success("Password reset email sent!");
      handleToggleForgotPassword();
    } catch (err) {
      toast.warning(err.message);
    }
  };

  setLoading(false);
  return (
    <>
      <div className="container">
        <div className="mt-4 m-2 row justify-content-center">
          <div className="mt-4 col-sm-10 col-md-8 card p-5 bg-light">
            <h4 className="text-center mb-3">Welcome to OtterBox</h4>
            <div className="d-flex justify-content-evenly">
              <GoogleSignInButton />
              {/* <FacebookSignInButton /> */}
            </div>
            <TextDivider text="Or continue with email" margin="my-3" />
            <form onSubmit={handleSubmit}>
              <div className="mb-3 input-group">
                <span className="input-group-text" style={{ width: "106px" }}>
                  Email:
                </span>
                <input type="email" className="form-control" required value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
              <div className="mb-3 input-group">
                <span className="input-group-text">Password:</span>
                <input
                  type="password"
                  className="form-control"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="d-grid gap-2">
                {!isPending && <button className="btn btn-primary mt-2">Login</button>}
                {isPending && (
                  <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                  </button>
                )}
              </div>
              {error && <div className="alert alert-danger">{error}</div>}
            </form>
            <div className="d-flex mt-2">
              <Link to="/signup" className="btn btn-link p-0">
                Register for account
              </Link>
              <button onClick={handleToggleForgotPassword} className="btn btn-link p-0 ms-auto">
                Forgotten password
              </button>
            </div>

            <hr />
          </div>
        </div>
        <Modal centered id="forgotPassword" show={showForgotPassword} onHide={() => setShowForgotPassword(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Reset Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Please enter your email address and we will send you a link to reset your password.</p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email address
                </label>
                <input
                  type="email"
                  className="form-control"
                  onChange={(e) => setForgotPasswordEmail(e.target.value)}
                  value={forgotPasswordEmail}
                  id="email"
                  aria-describedby="emailHelp"
                />
              </div>
              <button onClick={() => handleForgotPassword()} type="submit" className="btn btn-primary">
                Send reset link
              </button>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
