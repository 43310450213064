import { useReducer, useEffect, useState } from "react";
import { projectDatabase, timestamp } from "../config/config"; // Ensure projectDatabase is initialized properly

let initialState = {
  document: null,
  isPending: false,
  error: null,
  success: null,
};

const databaseReducer = (state, action) => {
  switch (action.type) {
    case "IS_PENDING":
      return { isPending: true, error: null, document: null, success: false };
    case "ADDED_DOC":
      return { document: action.payload, isPending: false, success: true, error: null };
    case "DELETED_DOC":
      return { document: null, isPending: false, success: true, error: null };
    case "SOFT_DELETED_DOC":
      return { document: action.payload, isPending: false, success: true, error: null };
    case "UPDATED_DOC":
      return { isPending: false, document: action.payload, success: true, error: null };
    case "FETCHED_DOC":
      return { isPending: false, document: action.payload, success: true, error: null };
    case "ERROR":
      return { isPending: false, document: null, success: false, error: action.payload };
    default:
      return state;
  }
};

export const useDatabase = (collection) => {
  const [response, dispatch] = useReducer(databaseReducer, initialState);
  const [isCancelled, setIsCancelled] = useState(false);

  const ref = projectDatabase.ref(collection);

  const dispatchIfNotCancelled = (action) => {
    if (!isCancelled) {
      dispatch(action);
    }
  };

  const addDocument = async (doc) => {
    dispatch({ type: "IS_PENDING" });
    try {
      const createdAt = timestamp(); // Realtime Database does not have `fromDate`, so use the current timestamp
      const newDocRef = ref.push(); // Create a new document reference
      const newDocId = newDocRef.key;

      await newDocRef.set({ ...doc, createdAt });
      dispatchIfNotCancelled({
        type: "ADDED_DOC",
        payload: { ...doc, createdAt, id: newDocId },
      });

      return { ...doc, id: newDocId };
    } catch (error) {
      dispatchIfNotCancelled({ type: "ERROR", payload: error.message });
      console.error("Error adding document:", error);
    }
  };

  const deleteDocument = async (id) => {
    dispatch({ type: "IS_PENDING" });

    try {
      await ref.child(id).remove();
      dispatchIfNotCancelled({ type: "DELETED_DOC" });
    } catch (error) {
      dispatchIfNotCancelled({ type: "ERROR", payload: "Could not delete" });
      console.error("Error deleting document:", error);
    }
  };

  const softDeleteDocument = async (id) => {
    dispatch({ type: "IS_PENDING" });

    try {
      const deleted = { deleted: true, deletedTime: timestamp() };
      await ref.child(id).update(deleted);
      dispatchIfNotCancelled({ type: "SOFT_DELETED_DOC", payload: { id, ...deleted } });
      return { id, ...deleted };
    } catch (error) {
      dispatchIfNotCancelled({ type: "ERROR", payload: error.message });
      console.error("Error soft deleting document:", error);
    }
  };

  const updateDocument = async (id, updates) => {
    dispatch({ type: "IS_PENDING" });

    try {
      await ref.child(id).update(updates);
      dispatchIfNotCancelled({
        type: "UPDATED_DOC",
        payload: { id, ...updates },
      });
      return { id, ...updates };
    } catch (error) {
      dispatchIfNotCancelled({ type: "ERROR", payload: error.message });
      console.error("Error updating document:", error);
    }
  };

  const getDocument = async (id) => {
    dispatch({ type: "IS_PENDING" });

    try {
      const snapshot = await ref.child(id).get();
      if (!snapshot.exists()) {
        throw new Error("Document not found");
      }

      const data = snapshot.val();
      dispatchIfNotCancelled({
        type: "FETCHED_DOC",
        payload: { id, ...data },
      });

      return { id, ...data };
    } catch (error) {
      dispatchIfNotCancelled({ type: "ERROR", payload: error.message });
      console.error("Error fetching document:", error);
    }
  };

  const getAllDocuments = async () => {
    dispatch({ type: "IS_PENDING" });

    try {
      const snapshot = await ref.get();
      if (!snapshot.exists()) {
        throw new Error("No documents found");
      }

      const data = [];
      snapshot.forEach((childSnapshot) => {
        data.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });

      dispatchIfNotCancelled({
        type: "FETCHED_DOC",
        payload: data, // Return all documents as an array
      });

      return data;
    } catch (error) {
      dispatchIfNotCancelled({ type: "ERROR", payload: error.message });
      console.error("Error fetching all documents:", error);
    }
  };

  const getAllDocumentsFiltered = async (filter) => {
    dispatch({ type: "IS_PENDING" });

    try {
      const snapshot = await ref.orderByChild(filter.field).equalTo(filter.value).get();
      if (!snapshot.exists()) {
        throw new Error("No filtered documents found");
      }

      const data = [];
      snapshot.forEach((childSnapshot) => {
        data.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });

      dispatchIfNotCancelled({
        type: "FETCHED_DOC",
        payload: data, // Return filtered documents as an array
      });

      return data;
    } catch (error) {
      dispatchIfNotCancelled({ type: "ERROR", payload: error.message });
      console.error("Error fetching filtered documents:", error);
    }
  };

  useEffect(() => {
    return () => setIsCancelled(true);
  }, []);

  return {
    addDocument,
    deleteDocument,
    softDeleteDocument,
    updateDocument,
    getDocument,
    getAllDocuments,
    getAllDocumentsFiltered,
    response,
  };
};
