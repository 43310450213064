import { React, createContext, useReducer, useState, useEffect, act } from "react";
import { projectAuth } from "../config/config";
import { projectFirestore } from "../config/config";
import LoadingOverlay from "../components/loadingOverlay/LoadingOverlay";

export const AuthContext = createContext();
export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      console.log("login");
      return {
        ...state,
        user: { ...state.user, ...action.payload }, // Merge additional user data action.payload,
        plainUser: state.plainUser || action.payload, // Preserve plainUser if already set
      };
    case "LOGOUT":
      console.log("logout");
      return { ...state, user: null, plainUser: null };
    case "AUTH_IS_READY":
      return {
        ...state,
        user: action.payload,
        authIsReady: true,
        plainUser: state.plainUser || action.payload, // Set plainUser only if it's not already set
      };
    case "SET_USER_DATA":
      // only set user data if payload is for the logged in user.
      if (state?.user?.uid === action.payload.profileData.id) {
        return {
          ...state,
          user: { ...state.user, ...action.payload }, // Merge additional user data
          plainUser: state.plainUser || state.user, // Ensure plainUser remains untouched
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let unsubFirestore; // Declare unsubFirestore outside of onAuthStateChanged

    const unsubAuth = projectAuth.onAuthStateChanged(async (user) => {
      if (user) {
        dispatch({ type: "AUTH_IS_READY", payload: user });
        setLoading(true);

        // Start listening for changes in Firestore once user is authenticated
        const userRef = projectFirestore.collection("users").doc(user.uid);
        unsubFirestore = userRef.onSnapshot((doc) => {
          if (doc.exists) {
            dispatch({ type: "SET_USER_DATA", payload: doc.data() });
          }
        });

        setLoading(false);
      } else {
        dispatch({ type: "AUTH_IS_READY", payload: null });
      }
    });

    // Cleanup auth state change listener and Firestore listener on unmount
    return () => {
      unsubAuth();
      if (unsubFirestore) unsubFirestore();
      setLoading(false);
    };
  }, []);

  if (loading) {
    return <LoadingOverlay />;
  }
  return <AuthContext.Provider value={{ ...state, dispatch }}>{children}</AuthContext.Provider>;
};
